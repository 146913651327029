import api from '../axios.service'

export default {

  review(data) {
    let getFormData = data

    let params = new FormData();
    params.append('order_inv', getFormData['order_inv']);
    for (var key in getFormData['image']) {
      if (getFormData['image'].hasOwnProperty(key)) {
        params.append('image[]', getFormData['image'][key]);
      }
    }

    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }

    return api.post('scan/review', params, config)
  },
  getOrderInvoice(order_id) {
    return api.get(`scan/get_order_inv/${order_id}`)
  },

}
