<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-card>
          <v-card-text>
            <div >

<!--              <QrcodeStream @init="onInit" @decode="onDecode" :paused="paused">-->
<!--              </QrcodeStream>-->
              <qrcode-stream  @init="onInit" @decode="onDecode" :paused="paused"></qrcode-stream>

              <center class="mt-5" v-if="code != ''">
                <h4><span> เลขใบสั่งชื่อ : {{ code }}</span> </h4>
                <div class="error" v-if="code === ''">
                  @{{ errorMessage }}
                </div>
              </center>
            </div>

            <div v-if="code">
              <div class="row" style="margin-top: 20px">
                <div class="col-md-4"></div>
                <div class="form-group col-md-4">
                  <span style="color: red">กรุณาเลือกไฟล์</span><br>
                  <v-file-input
                    multiple
                    v-model="files"></v-file-input>
                  <!--                <input required-->
                  <!--                       type="file" id="image" name="image[]" class="form-control" multiple=""><br>-->
                </div>
              </div>
              <div class="form-group col-md-4" id="preview_img">
              </div>
              <div class="row">
                <div class="col-md-4"></div>
                <div class="form-group col-md-4">
                  <center>
                    <v-btn
                      color="primary"
                      @click="submit"
                      type="submit" class="btn btn-success" style="margin-top:10px">Upload Image</v-btn>
                  </center>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<!--<script src="https://unpkg.com/vue-qrcode-reader@1.3.1/dist/vue-qrcode-reader.browser.js"></script>-->
<!--<link rel="stylesheet" href="https://unpkg.com/vue-qrcode-reader@1.3.1/dist/vue-qrcode-reader.css">-->
<script>
import {mdiPencil, mdiDelete} from '@mdi/js'
// import config from '../../../config'
import instance_scan from '../../../services/scan'
// import QrcodeStream from 'vue-qrcode-reader';
// import  QrcodeStream from 'vue-qrcode-reader';
import { QrcodeStream } from 'vue-qrcode-reader'
import Swal from "sweetalert2";


export default {
  components : { QrcodeStream },
  setup() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    code : '',
    configs: [],
    dialog: false,
    dialogDelete: false,
    search: '',
    headers: [
      {
        text: 'ลำดับ',
        value: 'index'
      },
      {text: 'ชื่อตั้งค่า', value: 'name'},
      {text: 'จำนวน/ค่า', value: 'value'},
      {text: 'สถานะ (เปิด/ปิด)', value: 'status'},
      {text: 'แก้ไขข้อมูล', value: 'actions'},
    ],
    reports: [
      {
        status: 'รอการจัดส่ง',
        total : 100,
        icon : '',
        count : 5,
        url : ''
},
    ],
    editedIndex: -1,
    editedItem: {
      productPoint_id: '',
      image_path: '',
      productPoint_name: '',
      detail: '',
      stock: 0,
      amount: 0,
      score: 0,
    },
    files : null,
    defaultItem: {
      productPoint_id: '',
      image_path: '',
      productPoint_name: '',
      detail: '',
      stock: 0,
      amount: 0,
      score: 0,
    },
    paused: false,
    errorMessage: 'กรุณาสแกน เลขที่บิลใบสั่งซื้อ!',
    data:[],
    pagination: {
      'current_page': 1,
      'per_page': 100,
    },
    sort: 'desc',
    search_all: '',
    per_page: '',
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม ตั้งค่าใช้จ่ายใน Order' : 'แก้ไข ตั้งค่าใช้จ่ายใน Order'
    },
  },

  watch: {
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    // this.getUser()
    // this.getConfig()
  },

  methods: {
    onDecode (content) {
      this.code = content
      if(this.code){
        this.getOrderInv()
      }
    },
    onInit (promise) {
      promise.then(() => {
      })
        .catch(error => {
          this.errorMessage = error.message
        })
    },
    list(){

    },
    Scan(){
    },
    editItem(item) {
      this.editedIndex = this.configs.indexOf(item)
      this.editedItem = {...item}
      this.dialog = true

    },
    getOrderInv() {
      // var data = {
      //   order_inv : this.code,
      // }

      instance_scan.getOrderInvoice(this.code).then(res => {
            if(res.data && res.data.length > 1) {

              Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด !!',
                text: 'มีเลขที่ใบสั่งซื้อซ้ำกันมากกว่า 1 กรุณาลองใหม่อีกครั้ง',
              })

              this.code = '';

            }else if (res.data && res.data.length == 1){
              this.code = res.data.order_inv;
            }else{
              this.code = '';
            }
      }).catch(err => {

      });

    //   axios.get('(url('admin/get_order_inv')); ?>'+'?order_inv='+this.code)
    // .then(response => {
    //     if(response.data && response.data.length > 1){
    //       console.log(response.data)
    //       alert('มีเลขที่ใบสั่งซื้อซ้ำกันมากกว่า1  กรุณาลองใหม่อีกครั้ง')
    //       location.reload()
    //     }
    //   })
    //     .catch(error => {
    //       console.log(error.message);
    //     });
    },

    // deleteItem(item) {
    //   this.editedIndex = this.configs.indexOf(item)
    //   this.editedItem = { ...item }
    //   this.dialogDelete = true
    // },
    submit(){
      var data = {
        order_inv : this.code,
        image : this.files
      }

      instance_scan.review(data).then(res => {
        Swal.fire({
          icon: 'success',
          title: res.data.message,
          showConfirmButton: false,
          timer: 1500
        })
        this.paused = true;
        this.code = '';
      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })
      });

    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },

    save() {

      var vm = this;
      let idx = vm.editedIndex
      let item = vm.editedItem
      if (this.editedIndex > -1) {


      } else {

      }
    },
    getConfig() {
      var data = {
        search: '',
        date: '',
        status: '',
      }

      // instance_config.get(data).then(res => {
      //   this.configs = res.data.data;
      // }).catch(err => {
      //
      // });
    },
    getUser() {
      // eslint-disable-next-line no-useless-concat
      const baseURL = `${process.env.VUE_APP_BASEURL}` + '/login'
      instance
        .get(`${baseURL}user?search=`)
        .then(res => {
          if (res.status === 200) {
            this.users = res.data.data
          }

          // Unauthorized Logout
          // eslint-disable-next-line no-empty
          if (res.status === 200 && res.data.error === 'Unauthorized') {
            localStorage.removeItem('token_authentication')
          }
        })
        .catch(error => {
          console.log(error)

        })
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
